import React from 'react';

interface ButtonProps {
    children: React.ReactNode;
    f: () => void;
}

export default function FB({ children, f }: ButtonProps) {
    return (
        <button onClick={f} className="group text-zinc-500 transition-all duration-300 ease-in-out">
            <span className="bg-left-bottom text-xl bg-gradient-to-r from-zinc-500 to-zinc-500 bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out">
                {children}
            </span>
        </button>
    );
};

