import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Chapter1 from "./pages/Chapter1"
import Chapter2 from "./pages/Chapter2"

function App() {
  return (
    <React.StrictMode>
      <Router >
        <div className="App">
          <div className='grid place-items-center'>
            <div className='w-full md:col-span-8 text-center'>
              <Routes>
                <Route path="/" element={ <Home/> } />
                <Route path="/chap1" element={ <Chapter1/> } />
                <Route path="/chap2" element={ <Chapter2/> } />
                {/* Add more routes as needed */}
              </Routes>
            </div>
          </div>
        </div>
      </Router>
    </React.StrictMode>
  );
}

export default App;
