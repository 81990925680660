
import { Link } from "react-router-dom";
import FB from "../components/FB";
import { useState } from "react";

import i1 from "../imgs/image1.png"

export default function Home() {

    const [text, setText] = useState("Chapter 1/73")

    const [restartsc, setrestart] = useState(false)

    const [b1, setb1] = useState(true)
    const [d1, setd1] = useState(false)
    const [b2, setb2] = useState(true)
    // const [b3, setb3] = useState(false)
    const [d2, setd2] = useState(false)
    const [b4, setb4] = useState(true)
    const [d3, setd3] = useState(false)
    const [d4, setd4] = useState(false)

    const restart = () => {
        window.location.reload();
    }

    const hb1 = () => {
        setText("You're at a bus stop.")
        setb1(false)
        setd1(true)
    }

    const hb2 = () => {
        setText("That was not very productive")
        setb2(false)
    }

    const hb3 = () => {
        setText("This place looks  familiar to you.")
        setd1(false)
        setd2(true)
    }

    const hb4 = () => {
        setText("aight u failed gg u have to restart now")
        setd2(false)
        setrestart(true)
    }

    const hb5 = () =>  {
        setText("You get on the bus and find a seat at the back. You feel like something is missing...")
        setd2(false)
        setd3(true)
    }

    const hb6 = () =>  {
        setText("You walk around for a little bit.")
        setd3(false)
        setd4(true)
    }

    return (
        <div className={`place-items-center grid mt-[40vh]`}>
            <div className="grid grid-cols-1 max-w-3xl w-full">
                <p className="text-center w-full justify-center mb-4">
                    {text}
                </p>
                {restartsc && (
                    <FB f={restart}>
                        restart
                    </FB>
                )}
                {b1 && (
                    <FB f={hb1}>
                        open eyes
                    </FB>
                )}
                {d1 && (
                    <div className="flex gap-8 justify-center">
                        {b2 && (
                            <FB f={hb2}>
                                fart
                            </FB>
                        )}
                        <FB f={hb3}>
                            look around
                        </FB>
                    </div>
                )}
                {d2 && (
                    <div className="grid place-items-center w-full">
                        <img className="object-cover w-full max-h-[30vh] blur-[2px]" src={i1} />
                        <div className="flex gap-8 justify-center">
                            {b4 && (
                                <FB f={hb4}>
                                    fart again
                                </FB>
                            )}
                            <FB f={hb5}>
                                get on the bus
                            </FB>
                        </div>
                    </div>
                )}
                {d3 && (
                    <FB f={hb6}>
                        get off at main @ bay
                    </FB>
                )}
                {d4 && (
                    <div className="place-items-center grid">
                        <p className="mt-[100vh] mb-[100vh]">The area is wide and large. You feel something missing.</p>
                        <p className="mb-[100vh]">I wonder what it could be.</p>
                        <p className="mb-[100vh]">Perhaps I should explore more....</p>
                        <Link to="/chap2" className="group text-zinc-500 transition-all duration-300 ease-in-out">
                        <span className="bg-left-bottom text-xl bg-gradient-to-r from-zinc-500 to-zinc-500 bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out">
                            next chapter
                        </span>
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
  }

