
import { Link } from "react-router-dom";

export default function Home() {
    return (
        <div className="place-items-center grid">
            <div className="grid grid-cols-1 max-w-3xl w-full">
                <p className="mt-[50vh] mb-[100vh]">welcome maysam...</p>
                <p className="mb-[100vh]">maybe you are wondering what this website is...</p>
                <p className="mb-[100vh]">however, i cant answer that yet...</p>
                <p className="mb-[100vh]">there's a lot left for you to explore...</p>
                <div className="mb-[50vh] hover:ml-48">
                    <Link to="/chap1" className="group text-zinc-500 transition-all duration-300 ease-in-out">
                    <span className="bg-left-bottom text-xl bg-gradient-to-r from-zinc-500 to-zinc-500 bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out">
                        continue
                    </span>
                    </Link>
                </div>
            </div>
        </div>
    );
  }

